/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { grpc } from 'grpc-web-client';
import _ from 'lodash';
import {
  UserRequest,
  UserChannelMessagesRequest,
  Message,
  InterviewMessageRequest,
} from '@/protoc/moonlight_pb';
import { MoonlightService } from '@/protoc/moonlight_pb_service';
import {
  grpcHost, isProduction, grpcAuthMetadata, protoTimestampToDate,
} from '@/helpers';

const namespaced = true;

const limit = 25;

// initial state
const state = {
  // If this changes - do a hard reset.
  userID: null,

  channels: null,
  pendingChannels: false,

  // CACHE of channelID => []messages
  // WHERE messages are ordered by ID DESCENDING
  messages: {},

  pendingMessages: false,
  pendingMessagesScroll: false,

  pendingCreate: false,

  success: false, // Set to True when a message is sent

  messagesFullyFetched: {}, // CACHE - channelID => true if no more messages

  errCode: null,
  errMsg: null,
};

const actions = {
  listChannels({ commit, state, rootState }, { userID }) {
    commit('mutateUserID', userID);
    commit('mutatePendingChannels', true);

    const req = new UserRequest();
    req.setUserId(userID);

    grpc.unary(MoonlightService.ListUserChannels, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        commit('mutatePendingChannels', false);
        // race condition
        if (state.userID !== Number(userID)) {
          return;
        }

        if (res.status === grpc.Code.OK) {
          commit('mutateChannels', res.message.getChannelsList());
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
  // This can be called in a poll function
  listChannelMessages({ commit, state, rootState }, {
    userID, channelID, updateLastRead, scroll,
  }) {
    // updateLastRead should only be sent when the user is looking at the message (!document.hidden)
    // scroll = true means get older messages; scroll = false means we are polling for new messages

    commit('mutateUserID', userID);

    if (state.messagesFullyFetched[channelID] === true && scroll) {
      // No more to fetch!
      return;
    }

    if (state.pendingMessages || state.pendingMessagesScroll) {
      return;
    }

    commit('mutatePendingMessages', true);

    const req = new UserChannelMessagesRequest();
    req.setChannelId(channelID);
    req.setActiveUserId(userID);
    req.setLimit(limit);
    req.setUpdateLastReadTime(updateLastRead);

    // If scrolling - we need to find offset
    if (scroll) {
      let beforeID = null;
      _.each(state.messages[channelID], (m) => {
        if (beforeID === null || m.message.id < beforeID) {
          beforeID = m.message.id;
        }
      });
      req.setBeforeId(beforeID);
    }

    grpc.unary(MoonlightService.ListUserChannelMessages, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          commit('mutateChannelMessages', {
            channelID,
            scroll,
            messages: res.message.getDataList(),
          });

          // mutate channel
          const ch = res.message.getChannel();
          const obj = ch.toObject();
          obj.lastMessageSent = protoTimestampToDate(ch.getLastMessageSent());
          obj.activeUserLastRead = protoTimestampToDate(ch.getActiveUserLastRead());
          obj.otherPartyLastRead = protoTimestampToDate(ch.getOtherPartyLastRead());

          commit('mutateUpdateChannelInCache', obj);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
        commit('mutatePendingMessages', false);
      },
    });
  },
  createMessage({ commit, state, rootState }, { activeUserID, channelID, text }) {
    // activeUserID comes from the path - it's whoever is listing the messages
    // Sending user ID will always be current user!
    commit('mutateUserID', activeUserID);

    commit('mutatePendingCreate', true);
    commit('mutateSuccess', false);

    const req = new Message();
    req.setChannelId(channelID);
    req.setUserId(rootState.auth.currentUser.id);
    req.setSource('web');
    req.setText(text);

    // technically announcements are the only general channels, so hard code :-(
    if (channelID === 'CASKUBFC0' || channelID === 'CAUJ51XB8') {
      req.setChannelType('channel');
    } else {
      req.setChannelType('group');
    }

    grpc.unary(MoonlightService.CreateMessage, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status === grpc.Code.OK) {
          const messageDataObj = {
            message: res.message.toObject(),
            photo: rootState.auth.currentUser.photo,
            name: rootState.auth.currentUser.fullName,
            isAdmin: rootState.auth.currentUser.admin,
            companyId: rootState.auth.currentUser.companyId,
            deactivated: false,
            createdAt: new Date(),
          };
          messageDataObj.message.createdAt = protoTimestampToDate(res.message.getCreatedAt());

          if (rootState.auth.company) {
            messageDataObj.companyName = rootState.auth.company.name;
          } else {
            messageDataObj.companyName = '';
          }

          commit('mutateAddMessageToCache', { channelID, messageDataObj });

          // update channel last read time
          let ch;

          for (let i = 0; i < state.channels.length; i += 1) {
            if (state.channels[i].id === channelID) {
              ch = { ...state.channels[i] };
            }
          }

          ch.activeUserLastRead = messageDataObj.message.createdAt;
          commit('mutateUpdateChannelInCache', ch);
          commit('mutateSuccess', true);
        } else {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
        commit('mutatePendingCreate', false);
      },
    });
  },
  archiveChannel({ commit, state, rootState }, { userID, channel }) {
    commit('mutateUserID', userID);

    // Disable in frontend immediately
    const ch = { ...channel };
    ch.canPost = false;
    ch.archived = true;
    commit('mutateUpdateChannelInCache', ch);

    const req = new InterviewMessageRequest();
    req.setCompanyId(ch.companyId);
    req.setDeveloperId(ch.developerId);

    grpc.unary(MoonlightService.ArchiveDirectInterviewSlackGroup, {
      metadata: grpcAuthMetadata(rootState.auth.session),
      debug: !isProduction(),
      request: req,
      host: grpcHost(),
      onEnd: (res) => {
        if (res.status !== grpc.Code.OK) {
          commit('mutateError', {
            code: res.status,
            msg: res.statusMessage,
          });
        }
      },
    });
  },
};

const mutations = {
  mutateResetError(state) {
    state.errCode = null;
    state.errMsg = null;
  },
  mutateUserID(state, id) {
    id = Number(id);
    // All of the stuff in this state store is namespaced to a user.
    // If you switch users - do a hard reset.
    // So - run mutateUserID before every action!
    if (state.userID !== id) {
      // DO A HARD RESET
      state.channels = null;
      state.pendingChannels = false;

      state.messages = {};
      state.pendingMessages = false;
      state.messagesFullyFetched = {};

      state.errCode = null;
      state.errMsg = null;
      state.userID = id;
    }
  },
  mutateChannels(state, channels) {
    const objs = [];
    _.each(channels, (ch) => {
      const obj = ch.toObject();
      obj.lastMessageSent = protoTimestampToDate(ch.getLastMessageSent());
      obj.activeUserLastRead = protoTimestampToDate(ch.getActiveUserLastRead());
      obj.otherPartyLastRead = protoTimestampToDate(ch.getOtherPartyLastRead());

      objs.push(obj);
    });
    state.channels = objs;
  },

  mutateChannelMessages(state, { channelID, messages, scroll }) {
    // If fewer messages came than limit - than we cannot scroll earlier
    if (messages.length < limit) {
      state.messagesFullyFetched[channelID] = true;
    }

    // We need to check if it has been SO LONG since the last fetch that we need
    // to REMOVE the message cache because it is no longer warm
    if (messages.length > 0 && !scroll && state.messsages && state.messages.length) {
      const oldestMessageIDInResponse = messages[messages.length - 1].getId();

      let oldestMessageAlreadyCached = false;
      _.each(state.messages[channelID], (m) => {
        if (m.id === oldestMessageIDInResponse) {
          oldestMessageAlreadyCached = true;
        }
      });
      if (!oldestMessageAlreadyCached) {
        state.messages[channelID] = null;
        // Vue.set(state.messages, channelID, null);
      }
    }

    // Now we go through new messages in REVERSE ORDER and,
    // IF THEY ARE NOT PRESENT, we PREPEND them to messages cache
    let cache = state.messages[channelID];
    let warmCache = true; // whether we need to check for collisions
    if (!cache) {
      cache = [];
      warmCache = false;
    }

    _.each(messages, (m) => {
      const data = m.toObject();
      data.message.createdAt = protoTimestampToDate(m.getMessage().getCreatedAt());

      if (warmCache) {
        // we need to check whether message already exists
        let alreadyExists = false;
        _.each(cache, (c) => {
          if (c.message.id === data.message.id) {
            alreadyExists = true;
          }
        });
        if (alreadyExists) {
          // skip
          return;
        }
      }

      // Just push now, and we will sort later.
      if (scroll) {
        cache.push(data);
      } else {
        cache.unshift(data);
      }
    });

    cache.sort((a, b) => b.message.id - a.message.id);

    state.messages[channelID] = cache;
    // Vue.set(state.messages, channelID, cache);
  },
  mutateAddMessageToCache(state, { channelID, messageDataObj }) {
    const cache = state.messages[channelID];
    cache.push(messageDataObj);
    cache.sort((a, b) => b.message.id - a.message.id);
    state.messages[channelID] = cache;
    // Vue.set(state.messages, channelID, cache);
  },
  mutatePendingChannels(state, pending) {
    state.pendingChannels = pending;
  },
  mutatePendingMessages(state, pending) {
    state.pendingMessages = pending;
  },
  mutatePendingMessagesScroll(state, pending) {
    state.pendingMessagesScroll = pending;
  },
  mutatePendingCreate(state, pending) {
    state.pendingCreate = pending;
  },
  mutateSuccess(state, val) {
    state.success = val;
  },
  mutateError(state, { code, msg }) {
    state.errCode = code;
    state.errMsg = msg;
  },
  mutateUpdateChannelInCache(state, obj) {
    for (let i = 0; i < state.channels.length; i += 1) {
      if (state.channels[i].id === obj.id) {
        state.channels[i] = obj;
        // Vue.set(state.channels, i, obj);
        return;
      }
    }
    // Not in cache - prepend
    state.channels.unshift(obj);
  },
};

const getters = {
  getChannelMessages(state) {
    return (channelID) => (state.messages[channelID] ? state.messages[channelID].slice() : null);
  },
  getChannelMessagesFullyFetched(state) {
    return (channelID) => state.messagesFullyFetched[channelID] === true;
  },
  getHasUnreadMessagesCount(state) {
    if (!state.channels) {
      return 0;
    }

    let res = 0;
    _.each(state.channels, (ch) => {
      if (!ch.archived && ch.unreadMessageCount > 0 && ch.type !== 'general') {
        res += ch.unreadMessageCount;
      }
    });

    return res;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
